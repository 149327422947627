import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.css']
})
export class DynamicFormComponent implements OnInit {
  hasError: boolean = false;
  isResponseReceived: Boolean = false;
  inputConfig: any[] = [];
  formData: { [key: string]: any } = {};
  formInfo: { [key: string]: any } = {};
  public entityId: any = localStorage.getItem('selected_entity');
  public doctorID: any = localStorage.getItem('doctor_id');
  imageChangedEvent: any = '';
  croppedImage: any = '';
  selectedImageField: string = ''; // Property for dynamic image field identifier
  showPrefilledImage: boolean = false; // Flag to control image display
  isEditMode: boolean = false; // Add this property

  nonImageFields: any[] = [];
  imageFields: any[] = [];

  private headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));

  constructor(private http: HttpClient, private router: Router, private spinner: NgxSpinnerService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getInputConfig().subscribe(config => {
      this.inputConfig = config.customFields;

      this.nonImageFields = this.inputConfig.filter(field => field.type !== 'image');
      this.imageFields = this.inputConfig.filter(field => field.type === 'image');

      // Initialize formInfo for each field
      this.nonImageFields.forEach(field => {
        this.formInfo[field.identifier] = ''; // Reset form fields
        if (field.type === 'dropdown' && field.options) {
          field.options = field.options.split(',');
        }
      });

      // Fetch existing entity data if editing
      if (this.doctorID) {
        this.isEditMode = true; // Set edit mode
        this.showPrefilledImage = true; // Set flag to show prefilled image
        this.getEntityData().subscribe(existingData => {
          console.log('Existing Data:', existingData);
          this.nonImageFields.forEach(field => {
            if (field.type === 'dropdown' && existingData.data[field.identifier]) {
              this.formInfo[field.identifier] = existingData.data[field.identifier];
            } else {
              this.formInfo[field.identifier] = existingData.data[field.identifier] || '';
            }
          });
          this.imageFields.forEach(field => {
            if (existingData.data[field.identifier]) {
              // Set formInfo to prefilled image data
              this.formInfo[field.identifier] = existingData.data[field.identifier];
              this.showPrefilledImage = true;
              // Ensure imageChangedEvent is cleared if an image is prefilled
              this.imageChangedEvent = null;
            } else {
              this.showPrefilledImage = false;
            }
          });
        });
      }
    });

    // Retrieve and apply stored primary color
    const storedPrimaryColor = localStorage.getItem('primaryColor');
    if (storedPrimaryColor) {
      console.log('Applying stored primary color:', storedPrimaryColor);
      document.documentElement.style.setProperty('--primary-color', storedPrimaryColor);
    } else {
      console.log('No stored primary color found');
    }
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.showPrefilledImage = false; // Hide prefilled image when new image is selected
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    console.log(this.croppedImage);

    // Check if selectedImageField is set
    if (this.selectedImageField) {
      this.formInfo[this.selectedImageField] = this.croppedImage;
    } else {
      console.error('No image field identifier selected');
    }
  }

  cropperReady() {
    // Cropper ready
  }

  loadImageFailed() {
    // Show message
  }

  public getInputConfig(): Observable<any> {
    return this.http.get<any>(`${environment.url}entity/${this.entityId}`, { headers: this.headers });
  }

  public getEntityData(): Observable<any> {
    return this.http.get<any>(`${environment.url}entity-data/${this.doctorID}`, { headers: this.headers });
  }

  public handleFileInput(event: any, identifier: string) {
    const file = event.target.files[0];
    if (file) {
      this.formInfo[identifier] = file;
    }
  }

  public saveButton() {
    this.hasError = false; // Reset error flag

    // Validate image fields
    for (const field of this.imageFields) {
      if (field.required && !this.formInfo[field.identifier]) {
        this.hasError = true; // Set error flag if any required image field is not filled
        break; // No need to check further
      }
    }

    // Validate non-image fields
    for (const field of this.nonImageFields) {
      if (field.required && !this.formInfo[field.identifier]) {
        this.hasError = true; // Set error flag if any required non-image field is not filled
        break; // Exit loop if an error is found
      }
    }

    // If there are errors, do not proceed
    if (this.hasError) {
      return; // Prevent submission
    }

    // Prepare the data to send
    const dataToSend = {
      entityId: this.entityId,
      data: this.formInfo
    };

    // Determine the headers
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));

    // Determine the URL and HTTP method based on the presence of doctorID
    let url: string;
    let method: string;
    let successMessage: string;
    let errorMessage: string;

    if (this.doctorID) {
      // Update existing doctor
      url = `${environment.url}entity-data/${this.doctorID}`;
      method = 'PATCH';
      successMessage = 'Data updated successfully';
      errorMessage = 'Failed to update data';
    } else {
      // Create new doctor
      url = `${environment.url}entity-data`;
      method = 'POST';
      successMessage = 'Data added successfully';
      errorMessage = 'Failed to add data';
    }

    this.spinner.show();

    // Make the HTTP request
    this.http.request(method, url, {
      body: dataToSend,
      headers: headers,
      observe: 'response'
    }).subscribe(
      (response: any) => {
        this.spinner.hide();
        if (response.status === (this.doctorID ? 200 : 201)) {
          this.toastr.success(successMessage);
          this.isResponseReceived = true;
          this.router.navigate([`/entity/${this.entityId}`]);
        } else {
          this.toastr.error(errorMessage);
        }
      },
      (error) => {
        this.spinner.hide();
        this.hasError = true;
        console.error('Error saving data', error);
        this.toastr.error(errorMessage);
      }
    );
  }


  setSelectedImageField(identifier: string) {
    this.selectedImageField = identifier;
  }
}
